import { useEffect } from 'react';

import { GatsbyBrowser } from 'gatsby';

import { initAmplitude } from '@helpers/amplitude';

const WrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  useEffect(() => {
    initAmplitude();
  }, []);

  return element;
};

export default WrapPageElement;
