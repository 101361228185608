export const ACTIVE_ENV = process.env.GATSBY_ACTIVE_ENV;

export const AMPLITUDE_KEY = process.env.GATSBY_AMPLITUDE_KEY;

export const RPC_URL_ETHEREUM = process.env.GATSBY_RPC_URL_ETHEREUM;

export const RPC_URL_SEPOLIA = process.env.GATSBY_RPC_URL_SEPOLIA;

export const RPC_URL_POLYGON = process.env.GATSBY_RPC_URL_POLYGON;

export const RPC_URL_HOLESKY = process.env.GATSBY_RPC_URL_HOLESKY;

export const WSS_URL_ETHEREUM = process.env.GATSBY_WSS_URL_ETHEREUM;

export const WSS_URL_POLYGON = process.env.GATSBY_WSS_URL_POLYGON;

export const WSS_URL_HOLESKY = process.env.GATSBY_WSS_URL_HOLESKY;

export const ETHPLORER_API_KEY = process.env.GATSBY_ETHPLORER_API_KEY;

export const KILN_API_KEY = process.env.GATSBY_KILN_API_KEY;

export const KILN_API_URL = process.env.GATSBY_KILN_API_URL;

export const KILN_TESTNET_API_KEY = process.env.GATSBY_KILN_TESTNET_API_KEY;

export const KILN_TESTNET_API_URL = process.env.GATSBY_KILN_TESTNET_API_URL;

export const DEFAULT_FIAT_CURRENCY = 'USD';

export const RATE_REFRESH_INTERVAL = 1000 * 60;

export const VERSE_TOKEN_CONTRACT_ADDRESS =
  '0x249cA82617eC3DfB2589c4c17ab7EC9765350a18';
export const VERSE_TOKEN_CONTRACT_ADDRESS_SEPOLIA =
  '0xa46c5C2aaCA6eCcd3213825272Bf3ce5f15e1BF5';

export const VERSE_STAKING_CONTRACT_ADDRESS =
  '0xd920556b0f3522bB1257923292A256F1e3023e07';
export const VERSE_STAKING_CONTRACT_ADDRESS_SEPOLIA =
  '0x2a70B50b26eA675f11c2812496dCE4538ab8f604';

export const STAKEWISE_ETH_VAULT_ADDRESS_HOLESKY =
  '0xbffd2f4371b6635ca55eb4fb37b78ea8a4d966d0';
export const STAKEWISE_ETH_VAULT_ADDRESS =
  '0x91211a4965e75152cb549b308f8ba398c3ab337e';

const VERSE_REWARDS_CONTRACT_ADDRESS_DEV =
  '0x76eCE79847c37DC4356b6Db0B26bFE179be5564f';
const VERSE_REWARDS_CONTRACT_ADDRESS_PROD =
  '0x826a6FB5764048449a99bd889f29381ec26D9bc7';

export const VERSE_REWARDS_CONTRACT_ADDRESS =
  ACTIVE_ENV === 'development'
    ? VERSE_REWARDS_CONTRACT_ADDRESS_DEV
    : VERSE_REWARDS_CONTRACT_ADDRESS_PROD;

export const KILN_USDT_VAULT_ADDRESS_SEPOLIA =
  '0x9b80443f910832a6eed6cef5b95bd9d1dae424b5';
export const KILN_USDT_CONTRACT_ADDRESS_SEPOLIA =
  '0xaA8E23Fb1079EA71e0a56F48a2aA51851D8433D0';

export const KILN_USDC_VAULT_ADDRESS =
  '0x50913b45f278c39c8a7925b3c31dd88b95fb1aa2';
export const KILN_USDC_CONTRACT_ADDRESS =
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48';

export const KILN_USDC_VAULT_ADDRESS_SEPOLIA =
  '0xDea01Fc5289aF2c440Ca65582e3C44767C0fcf08';
export const KILN_USDC_CONTRACT_ADDRESS_SEPOLIA =
  '0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8';

export const SECONDS_IN_YEAR = 60 * 60 * 24 * 365;

export const CONTRACT_PROCESSING_DAYS = 7;

export const DEEP_LINKS = {
  buy: {
    verse:
      'bitcoincom://buy/ETH_BLOCKCHAIN-ERC_20_PROTOCOL-0x249cA82617eC3DfB2589c4c17ab7EC9765350a18',
    eth: 'bitcoincom://buy/ETH_BLOCKCHAIN-ETH_PROTOCOL-ETH',
  },
  swap: {
    verse:
      'bitcoincom://swap/ETH_BLOCKCHAIN-ERC_20_PROTOCOL-ETH/ETH_BLOCKCHAIN-ERC_20_PROTOCOL-0x249ca82617ec3dfb2589c4c17ab7ec9765350a18',
    eth: 'bitcoincom://swap/BTC_BLOCKCHAIN-BTC_PROTOCOL-BTC/ETH_BLOCKCHAIN-ETH_PROTOCOL-ETH/',
  },
};

export const BASE_URL = process.env.GATSBY_BASE_URL;

export const NEKO_BASE_URL = process.env.GATSBY_NEKO_BASE_URL;

// yah, this could probably go into env vars but dev wss is pretty bad
export const WSS_PRICE_URL = `wss://markets-ws.api.bitcoin.com/v1/live-price`;
