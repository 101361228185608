import {
  EarnEthStakingCtaTappedProperties,
  EarnEthStakingDepositResultProperties,
  EarnEthStakingOnboardingProperties,
  EarnEthStakingUnstakeResultProperties,
  EarnEthStakingWithdrawResultProperties,
  EarnVerseStakingClaimResultProperties,
  EarnVerseStakingCtaTappedProperties,
  EarnVerseStakingDepositResultProperties,
  EarnVerseStakingOnboardingProperties,
  EarnVerseStakingWithdrawResultProperties,
  PromiseResult,
  Result,
  RewardsClaimCompletedProperties,
  RewardsClaimStartedProperties,
  RewardsCtaTappedProperties,
  RewardsQuestStartedProperties,
  ampli,
} from '@bitcoin-portal/web-ampli';

import { AMPLITUDE_KEY } from '../context/constants';

interface AmpConfig {
  sessionId?: number;
  deviceId?: string;
  defaultTracking?: boolean;
}

export const initAmplitude = async () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('sessionId');
    const deviceId = urlParams.get('deviceId');

    const configuration: AmpConfig = { defaultTracking: true };

    if (sessionId) {
      configuration.sessionId = Number(sessionId);
    }
    if (deviceId) {
      configuration.deviceId = deviceId;
    }

    if (ampli.isLoaded) {
      // adding this to cleanup console where this gets loadedtwice
      return;
    }

    ampli.load({
      client: {
        apiKey: AMPLITUDE_KEY,
        configuration,
      },
    });
  } catch (e) {
    console.error('Error initialising Amplitude: ', e);
  }
};

export const getEntrypoint = (): string => {
  try {
    const topLevelPath = window.location.pathname.split('/')?.[1];
    return topLevelPath || 'top';
  } catch (e) {
    return '';
  }
};

export interface AmpliMessages {
  earnVerseStakingCtaTapped: EarnVerseStakingCtaTappedProperties;
  earnVerseStakingDepositResult: EarnVerseStakingDepositResultProperties;
  earnVerseStakingClaimResult: EarnVerseStakingClaimResultProperties;
  earnVerseStakingOnboarding: EarnVerseStakingOnboardingProperties;
  earnVerseStakingWithdrawResult: EarnVerseStakingWithdrawResultProperties;
  earnEthStakingCtaTapped: EarnEthStakingCtaTappedProperties;
  earnEthStakingDepositResult: EarnEthStakingDepositResultProperties;
  earnEthStakingOnboarding: EarnEthStakingOnboardingProperties;
  earnEthStakingUnstakeResult: EarnEthStakingUnstakeResultProperties;
  earnEthStakingWithdrawResult: EarnEthStakingWithdrawResultProperties;
  rewardsClaimCompleted: RewardsClaimCompletedProperties;
  rewardsClaimStarted: RewardsClaimStartedProperties;
  rewardsCtaTapped: RewardsCtaTappedProperties;
  rewardsQuestStarted: RewardsQuestStartedProperties;
}

export const logAmplitudeEvent = async <K extends keyof AmpliMessages>(
  name: K,
  eventData: AmpliMessages[K],
): Promise<void> => {
  try {
    if (!ampli[name]) {
      throw new Error("Ampli event doesn't exist");
    }

    (ampli[name] as (data: AmpliMessages[K]) => PromiseResult<Result>)(
      eventData,
    );
  } catch (e) {
    console.error('Error sending amplitude event: ', e);
  }
};
